<template>
  <div class="lg:flex lg:h-full lg:flex-col mx-auto" style=" max-width: 1200px;">
    <header class="flex items-center justify-center border-gray-200 px-6 py-4 lg:flex-none static">
      <div class="flex items-center">
        <div class="relative flex items-center rounded-md bg-white shadow-sm md:items-stretch">
          <button type="button" class="flex h-9 w-12 items-center justify-center rounded-l-md border-t border-b border-l border-gray-300 pr-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pr-0 md:hover:bg-gray-50" @click="() => addMonth(-1)">
            <span class="sr-only">Previous month</span>
            <svg
              class="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path fill-rule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clip-rule="evenodd" />
            </svg>
          </button>
          <button type="button" class="hidden border-t border-b border-gray-300 px-3.5 text-sm font-semibold text-gray-900 hover:bg-gray-50 focus:relative md:block">
            {{ capitalize(monthToString(month)) }} {{ year }}
          </button>
          <span class="relative -mx-px h-5 w-px bg-gray-300 md:hidden"></span>
          <button type="button" class="flex h-9 w-12 items-center justify-center rounded-r-md border-t border-b border-r border-gray-300 pl-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pl-0 md:hover:bg-gray-50" @click="() => addMonth(1)">
            <span class="sr-only">Next month</span>
            <svg
              class="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path fill-rule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clip-rule="evenodd" />
            </svg>
          </button>
        </div>
      </div>
    </header>

    <div class="relative overflow-x-scroll">
      <div class="shadow-lg rounded-sm ring-1 border border-gray-300 ring-black ring-opacity-5 lg:flex lg:flex-auto lg:flex-col" style="min-width: 920px;">
        <div class="grid gap-px border-b border-gray-300 bg-gray-200 text-center text-xs font-semibold leading-6 text-gray-700 lg:flex-none" :class="`grid-cols-${7 + suffixHeaders.length}`">
          <div class="flex justify-center bg-white py-2">
            <span>Ma</span>
          </div>
          <div class="flex justify-center bg-white py-2">
            <span>Di</span>
          </div>
          <div class="flex justify-center bg-white py-2">
            <span>Wo</span>
          </div>
          <div class="flex justify-center bg-white py-2">
            <span>Do</span>
          </div>
          <div class="flex justify-center bg-white py-2">
            <span>Vr</span>
          </div>
          <div class="flex justify-center bg-white py-2">
            <span>Za</span>
          </div>
          <div class="flex justify-center bg-white py-2">
            <span>Zo</span>
          </div>
          <div
            v-for="(h, indexSuffixHeader) in suffixHeaders"
            :key="h"
            class="flex justify-center bg-white py-2"
            :class="{ 'ml-1': indexSuffixHeader === 0 }"
          >
            {{ formatHeader(h) }}
          </div>
        </div>

        <div class="flex bg-gray-200 text-xs/6 text-gray-700 lg:flex-auto">
          <div class="grid lg:gap-px w-full" :class="`grid-cols-${7 + suffixHeaders.length} grid-rows-${weekAndDays.length}`">
            <template v-for="(week, indexWeek) in weekAndDays">
              <div
                v-for="weekItem in week"
                :key="`${indexWeek}-${weekItem.date}`"
                class="relative px-3 py-2 w-full"
                :class="[
                  weekItem.isCurrentMonth ? 'bg-white' : 'bg-gray-100 opacity-50',
                ]"
              >
                <time :datetime="weekItem.date">{{ weekItem.dag }}</time>
                <slot name="item-date" :date="weekItem.date"></slot>
              </div>

              <div
                v-for="(h, indexSuffixHeader) in suffixHeaders"
                :key="`${indexWeek}-${indexSuffixHeader}-${h}`"
                class="relative bg-white px-3 py-2"
                :class="{ 'ml-1': indexSuffixHeader === 0 }"
              >
                <slot :name="`item-${h}`" :dates="week.map(el => el.date)">
                  <span>{{ formatHeader(h) }}</span>
                </slot>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, defineModel, toValue, watch } from 'vue'

import { ARRAY_DEFAULT_EMPTY } from '@/constants/PROPS'
import { dateString, monthToString } from '@/functions/formatDate'
import { capitalize, formatHeader } from '@/functions/formatText'
import useCalendar from '@/hooks/useCalendar'

const props = defineProps({
  suffixHeaders: ARRAY_DEFAULT_EMPTY,
})

const monthModel = defineModel('month', { type: [Number, String], default: () => new Date().getMonth() + 1 })
const yearModel = defineModel('year', { type: [Number, String], default: () => new Date().getFullYear() })

const selectedDate = defineModel('modelValue', { type: String })
selectedDate.value = dateString(new Date())

const {
  year,
  month,
  days,
  addMonth,
  // selectDayDate,
} = useCalendar(selectedDate, {
  defaultMonth: props.month,
  defaultYear: props.year,
})

watch([month, year], () => {
  monthModel.value = month.value
  yearModel.value = year.value
}, { immediate: true })

const weekAndDays = computed(() => {
  const allDays = toValue(days)
  return allDays.reduce((acc, day, index) => {
    if (index % 7 === 0) {
      acc.push([day])
    } else {
      acc[acc.length - 1].push(day)
    }
    return acc
  }, [])
})
</script>
