import { computed, ref, toValue, watch } from 'vue'

import { dateString } from '@/functions/formatDate'

export const sameDay = (a, b = new Date()) =>
  a.getDate() === b.getDate() && a.getMonth() === b.getMonth() && a.getFullYear() === b.getFullYear()

export default (modelValue, options = {}) => {
  const minDate = dateString(options?.minDate || '2024-01-01')
  const maxYear = Math.min(2999, options?.maxYear > 1900 ? Number(options.maxYear) : 2999)
  const geslotenDatums = (options?.geslotenDatums || []).map(date => dateString(date))
  const isGesloten = date => geslotenDatums.length ? geslotenDatums.includes(date) : false

  const getSafeValue = value => typeof value !== 'undefined' && value !== null ? dateString(toValue(value)) : null
  const updateModelValue = value => {
    const newValue = getSafeValue(value)
    if (modelValue.value !== newValue) modelValue.value = newValue
  }
  watch(modelValue, updateModelValue)
  updateModelValue(modelValue.value)

  const today = new Date()

  const visibleYear = ref(options.defaultYear > 0 ? options.defaultYear : new Date(modelValue.value || minDate).getFullYear())
  const visibleMonth = ref(options.defaultMonth > 0 ? options.defaultMonth : new Date(modelValue.value || minDate).getMonth() + 1)

  const addMonth = (n = 0) => {
    const newDate = new Date(visibleYear.value, visibleMonth.value - 1 + n, 15)

    const yyyy = newDate.getFullYear()
    if (maxYear && yyyy > maxYear) return

    const minDateJS = new Date(minDate)
    minDateJS.setDate(1)
    if (newDate < minDateJS) return

    visibleYear.value = yyyy
    visibleMonth.value = newDate.getMonth() + 1
  }

  const selectDayDate = date => {
    modelValue.value = dateString(date)
    const newDate = new Date(date)
    visibleYear.value = newDate.getFullYear()
    visibleMonth.value = newDate.getMonth() + 1
  }

  const days = computed(() => {
    const currentDate = new Date(visibleYear.value, visibleMonth.value - 1, 1, 23, 59, 59, 999)
    const lastDay = new Date(visibleYear.value, visibleMonth.value, 0, 23, 59, 59, 999)

    while (currentDate.getDay() !== 1) {
      currentDate.setDate(currentDate.getDate() - 1)
    }

    while (lastDay.getDay() !== 0) {
      lastDay.setDate(lastDay.getDate() + 1)
    }

    const result = []
    // eslint-disable-next-line no-unmodified-loop-condition
    while (currentDate <= lastDay) {
      const formattedDate = dateString(currentDate)
      result.push({
        disabled: isGesloten(formattedDate) || formattedDate < minDate,
        date: formattedDate,
        isCurrentMonth: currentDate.getMonth() + 1 === visibleMonth.value,
        isToday: sameDay(currentDate, today),
        isSelected: modelValue.value && sameDay(currentDate, new Date(modelValue.value)),
        dag: new Date(formattedDate).getDate(),
      })
      currentDate.setDate(currentDate.getDate() + 1)
    }

    return result
  })

  return {
    year: visibleYear,
    month: visibleMonth,
    days,
    addMonth,
    selectDayDate,
  }
}
