<template>
  <div>
    <h1 class="text-2xl font-semibold text-gray-900 mb-2">
      Prestaties overzicht
    </h1>

    <UISelectChauffeur
      v-model="apiData.user_id"
      active
      class="w-full max-w-sm"
      label
    />

    <div v-if="apiData.user_id > 0" class="w-full overflow-x-scroll">
      <UILoading v-if="loading" />

      <UICalendar
        v-else
        v-model:month="apiData.month"
        v-model:year="apiData.year"
        :suffix-headers="['Week_Totaal']"
        class="w-[900px]"
      >
        <template #item-date="{ date }">
          <ol>
            <li v-if="getAfwezigheden(date)?.length" class="flex flex-col gap-0.5">
              <button
                v-for="item in getAfwezigheden(date)"
                :key="item.id"
                class="inline-flex bg-red-100 px-2 p-1 rounded w-full justify-between gap-2"
                @click="alertAfwezigheid(item)"
              >
                <span class="font-bold uppercase text-center w-full">
                  {{ item.reason }}
                </span>
              </button>
            </li>
            <li v-if="getDateResultaat(date).totaal > 0">
              <div class="group flex flex-col inline-flex bg-yellow-100 p-1 rounded w-full">
                <p class="flex-auto truncate font-medium text-gray-900 group-hover:text-indigo-600">
                  Shift totalen
                </p>
                <div class="flex flex-col gap-0 font-bold">
                  <time class="ml-3 block flex-none text-green-500 group-hover:text-green-600">
                    {{ millisecondsToHMSWritten(getDateResultaat(date).met) }}
                  </time>
                  <template v-if="showTotalen">
                    <time class="ml-3 block flex-none text-red-500">
                      {{
                        millisecondsToHMSWritten(getDateResultaat(date).zonder)
                      }}
                    </time>
                    <hr>
                    <time class="ml-3 block flex-none text-blue-500">
                      {{
                        millisecondsToHMSWritten(getDateResultaat(date).totaal)
                      }}
                    </time>
                  </template>
                </div>
              </div>
            </li>
          </ol>
        </template>

        <template #item-Week_Totaal="{ dates }">
          <ol class="mt-2">
            <li>
              <div class="group inline-flex bg-blue-100 p-1 rounded w-full">
                <div class="flex flex-col items-end gap-0 pb-2 font-bold">
                  <time class="ml-3 hidden flex-none text-green-500 group-hover:text-green-600 xl:block">
                    {{ millisecondsToHMSWritten(getWeekResultaat(dates).met) }}
                  </time>
                  <template v-if="showTotalen">
                    <time class="ml-3 hidden flex-none text-red-500 group-hover:text-red-600 xl:block">
                      {{
                        millisecondsToHMSWritten(getWeekResultaat(dates).zonder)
                      }}
                    </time>
                    <hr>
                    <time class="ml-3 hidden flex-none text-blue-500 group-hover:text-blue-600 xl:block">
                      {{
                        millisecondsToHMSWritten(getWeekResultaat(dates).totaal)
                      }}
                    </time>
                  </template>
                </div>
              </div>
            </li>
          </ol>
        </template>
      </UICalendar>

      <div class="grid lg:gap-px w-full xl:grid-cols-8 mt-2">
        <div class="col-start-7 col-span-0 text-left border flex flex-col gap-2 justify-between font-bold p-2">
          <span>1 - {{ maxDays }}
            {{ capitalize(monthToString(apiData.month)) }}:</span>
          <time class="ml-3 text-green-500">
            {{ millisecondsToHMSWritten(maandResultaat.met) }}
          </time>
          <template v-if="showTotalen">
            <time class="ml-3 hidden flex-none text-red-500 group-hover:text-red-600 xl:block">
              {{ millisecondsToHMSWritten(maandResultaat.zonder) }}</time>
            <hr>
            <time class="ml-3 hidden flex-none text-blue-500 group-hover:text-blue-600 xl:block">
              {{ millisecondsToHMSWritten(maandResultaat.totaal) }}
            </time>
          </template>
        </div>
      </div>

      <div class="inline-flex flex-col -mt-4">
        <button v-if="!loading && store.getters.hasMasterPermission" class="btn purple mt-4" @click="showTotalen = !showTotalen">
          Toggle
        </button>
        <div v-if="!loading" class="text-xs lg:text-sm italic card inline-flex flex-col gap-3 mt-2">
          <b>Legende:</b>
          <ul class="flex flex-col gap-2">
            <li class="text-green-500">
              <b>GROEN:</b> Gewerkt
            </li>
            <li v-if="showTotalen" class="text-red-500">
              <b>RED:</b> Zonder
            </li>
            <li v-if="showTotalen" class="text-blue-500">
              <b>BLUE:</b> Totaal
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'

import UICalendar from '@/components/UI/Calendar.vue'
import UILoading from '@/components/UI/Loading.vue'
import UISelectChauffeur from '@/components/UI/Select/Chauffeur.vue'

import {
  dateWrittenTimeString,
  millisecondsToHMSWritten,
  monthToString,
} from '@/functions/formatDate'
import { capitalize } from '@/functions/formatText'
import useGetApi from '@/hooks/useGetApi'

const today = new Date()

const store = useStore()
const showTotalen = ref(!!store.getters.hasMasterPermission)

const apiData = ref({
  month: today.getMonth() + 1,
  year: today.getFullYear(),
  user_id: null,
})

const apiDataAfwezigheden = computed(() => ({
  ...apiData.value,
  status: 'ACCEPTED',
  roundDay: true,
  tabel: 'afwezigheden',
}))

const maxDays = computed(() =>
  new Date(apiData.value.year, apiData.value.month, 0).getDate(),
)

const { data: afwezigheden } = useGetApi(
  'BESCHIKBAARHEID_LIST',
  apiDataAfwezigheden,
  {
    skipMounted: true,
    watch: true,
  },
)

const { data, loading } = useGetApi('PRESTATIES_LIST', apiData, {
  skipMounted: true,
  watch: true,
})

const alertAfwezigheid = item => {
  alert(
    `Verlof: ${item.reason}\nVan ${dateWrittenTimeString(
      item.start,
    )} tot ${dateWrittenTimeString(item.einde)}\n\n${item.opmerkingen || ''
    }`.trim(),
  )
}

const getAfwezigheden = date => {
  const list = afwezigheden.value?.list || []
  return list.filter(item => item.start < date && item.einde > date)
}

const getDateResultaat = date => {
  const record = data.value?.[date] || {}
  return {
    totaal: (record.met || 0) + (record.zonder || 0),
    met: record.met || 0,
    zonder: record.zonder || 0,
  }
}

const getWeekResultaat = dates => {
  return dates.reduce(
    (acc, date) => {
      const resultaat = getDateResultaat(date)
      return {
        totaal: acc.totaal + resultaat.totaal,
        met: acc.met + resultaat.met,
        zonder: acc.zonder + resultaat.zonder,
      }
    },
    {
      totaal: 0,
      met: 0,
      zonder: 0,
    },
  )
}

const maandResultaat = computed(() => {
  const dates = []
  for (let d = 1; d <= maxDays.value; d++) {
    dates.push(
      `${apiData.value.year}-${apiData.value.month
        .toString()
        .padStart(2, '0')}-${d.toString().padStart(2, '0')}`,
    )
  }

  return dates.reduce(
    (acc, date) => {
      const resultaat = getDateResultaat(date)
      return {
        totaal: acc.totaal + resultaat.totaal,
        met: acc.met + resultaat.met,
        zonder: acc.zonder + resultaat.zonder,
      }
    },
    {
      totaal: 0,
      met: 0,
      zonder: 0,
    },
  )
})
</script>

<style scoped>
time,
li,
p {
  display: block;
  white-space: nowrap;
}

.w-[900px] {
  width: 900px;
}
</style>
